import { Dialog, Transition } from "@headlessui/react"
import { useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"

export default function DeleteDataDialog({
  isShow,
  selectedData,
  isDeleting,
  onClose,
  onDelete
}) {

  const { screenSize } = useSelector(state => state.global)

  if (!selectedData) return null;

  return (
    <Transition
      appear
      show={isShow}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "90"
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-1 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="px-2 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500 outline-none"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 mb-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                >
                  {!isDeleting ?
                    (
                      <>
                        <div className="space-y-3">
                          <div className="text-center">
                            Data berikut akan dihapus:
                          </div>
                          <div>
                            <div className="flex space-between">
                              <div className="flex-1 text-right">
                                Kode
                              </div>
                              <div className="px-3">
                                :
                              </div>
                              <div className="flex-1 text-left">
                                {selectedData.kodeMateri}
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="flex-1 text-right">
                                Jenjang
                              </div>
                              <div className="px-3">
                                :
                              </div>
                              <div className="flex-1 text-left">
                                {selectedData.namaMateri}
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            Lanjutkan?
                          </div>
                        </div>
                        <div className="py-2 flex justify-center space-x-5">
                          <button
                            className="rounded-full py-1 px-8 bg-red-500 hover:bg-red-700 text-white"
                            onClick={onClose}
                          >
                            Batal
                          </button>
                          <button
                            className="rounded-full py-1 px-5 bg-blue-500 hover:bg-blue-700 text-white"
                            onClick={onDelete}
                          >
                            Hapus
                          </button>
                        </div>
                      </>
                    )
                    :
                    (
                      <div
                        className="flex items-center justify-center text-blue-600 bg-white"
                        style={{ minHeight: "50px" }}
                      >
                        <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                      </div>
                    )
                  }
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}