import axios from "axios"

export const login = async (payload) => {
  const finalUrl =
    window._HOST + "admin/login"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}