import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

const initAdmin = {
  kodeAdmin: null,
  namaAdmin: null
}

const initialState = {
  admin: initAdmin,
  homeActivePage: 'dashboard',
  screenSize: {
    width: 0,
    height: 0
  },
  isHomeProcess: false,
  changePasswordDialog: {
    isOpen: false
  }
}

export const GlobalSlice = createSlice({
  name: 'Global',
  initialState,
  reducers: {
    resetAdmin: state => {
      state.admin.kode = null
      state.admin.nama = null
    },
    setAdmin: (state, { payload }) => {
      state.admin.kodeAdmin = payload.kode
      state.admin.namaAdmin = payload.nama
    },
    setScreenSize: (state, { payload }) => {
      state.screenSize = payload
    },
    setHomeActivePage: (state, { payload }) => {
      state.homeActivePage = payload
    },
    setAdminLogout: state => {
      state.admin.kodeAdmin = null
      state.admin.namaAdmin = null
    },
    setIsHomeProcess: (state, { payload }) => {
      state.isHomeProcess = payload
    },
    openChangePasswordDialog: (state) => {
      state.changePasswordDialog.isOpen = true
    },
    closeChangePasswordDialog: (state) => {
      state.changePasswordDialog.isOpen = false
    }
  }
})

export const isLoggedIn = (admin) => admin.kodeAdmin !== null

export const {
  resetAdmin,
  setAdmin,
  setScreenSize,
  setHomeActivePage,
  setAdminLogout,
  setIsHomeProcess,
  openChangePasswordDialog,
  closeChangePasswordDialog
} = GlobalSlice.actions

export default GlobalSlice.reducer