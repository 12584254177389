import { useEffect, useState } from "react"
import { RiEdit2Line, RiLoader5Fill } from "react-icons/ri"
import { GiGraduateCap, GiBookshelf } from "react-icons/gi"
import { FaPencilRuler } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { setHomeActivePage } from "../../../app/GlobalSlice"
import { getDataSummary as getDataSummarySoal } from "../../Soal/Apis/Api"
import { getDataSummary as getDataSummaryJenjang } from "../../Jenjang/Apis/Api"
import { getDataSummary as getDataSummaryMapel } from "../../Mapel/Apis/Api"
import { getDataSummary as getDataSummaryMateri } from "../../Materi/Apis/Api"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { format } from "date-fns"
import { useHistory } from "react-router"
import { notifyError } from "../../../utils/Notification"

export default function MainMenu() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [dashboardModel, setDashboardModel] = useState({
    dataSoal: {
      isLoad: false,
      totalData: 0,
      latestInput: null
    },
    dataJenjang: {
      isLoad: false,
      totalData: 0,
      latestInput: null
    },
    dataMapel: {
      isLoad: false,
      totalData: 0,
      latestInput: null
    },
    dataMateri: {
      isLoad: false,
      totalData: 0,
      latestInput: null
    }
  })

  useEffect(() => {

    setDashboardModel({
      ...dashboardModel,
      dataSoal: {
        ...dashboardModel.dataSoal,
        isLoad: true
      },
      dataMateri: {
        ...dashboardModel.dataSoal,
        isLoad: true
      },
      dataMepel: {
        ...dashboardModel.dataSoal,
        isLoad: true
      },
      dataJenjang: {
        ...dashboardModel.dataSoal,
        isLoad: true
      },
    })

    getDataSummarySoal()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setDashboardModel(latestM => ({
              ...latestM,
              dataSoal: {
                isLoad: false,
                totalData: payload.totalData,
                latestInput: payload.latestInput ? format(new Date(payload.latestInput), "dd/MM/yyyy") : '-'
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setDashboardModel(latestM => ({
          ...latestM,
          dataSoal: {
            ...latestM.dataSoal,
            isLoad: false,
          }
        }))
      })

    getDataSummaryJenjang()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setDashboardModel(latestM => ({
              ...latestM,
              dataJenjang: {
                isLoad: false,
                totalData: payload.totalData,
                latestInput: payload.latestInput ? format(new Date(payload.latestInput), "dd/MM/yyyy") : '-'
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setDashboardModel(latestM => ({
          ...latestM,
          dataJenjang: {
            ...latestM.dataJenjang,
            isLoad: false,
          }
        }))
      })

    getDataSummaryMapel()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setDashboardModel(latestM => ({
              ...latestM,
              dataMapel: {
                isLoad: false,
                totalData: payload.totalData,
                latestInput: payload.latestInput ? format(new Date(payload.latestInput), "dd/MM/yyyy") : '-'
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setDashboardModel(latestM => ({
          ...latestM,
          dataMapel: {
            ...latestM.dataMapel,
            isLoad: false,
          }
        }))
      })

    getDataSummaryMateri()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setDashboardModel(latestM => ({
              ...latestM,
              dataMateri: {
                isLoad: false,
                totalData: payload.totalData,
                latestInput: payload.latestInput ? format(new Date(payload.latestInput), "dd/MM/yyyy") : '-'
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setDashboardModel(latestM => ({
          ...latestM,
          dataMateri: {
            ...latestM.dataMateri,
            isLoad: false,
          }
        }))
      })


  }, [])

  useEffect(() => {
    dispatch(setHomeActivePage('dashboard'))
  }, [])

  return (
    <div
      className="w-full flex justify-center"
    >
      <div
        className="w-full flex space-x-5"
      >
        <div className="w-1/4 space-y-3">
          <div className="w-full p-3 py-10 rounded-lg bg-white space-y-5 relative">
            {dashboardModel.dataSoal.isLoad && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-blue-600"
              >
                <RiLoader5Fill className={"h-14 w-14 animate-spin"} />
              </div>
            )}
            <div
              className="font-bold"
              style={{ fontFamily: "Nunito", fontSize: "18px" }}
            >
              Data Soal
            </div>
            <div className="flex justify-center">
              <div className="p-5 rounded-full bg-yellow-500">
                <RiEdit2Line className="h-20 w-20" color="white" />
              </div>
            </div>
            <div
              className="px-2"
              style={{ fontSize: "14px" }}
            >
              <div className="flex space-between">
                <div className="flex-1 text-right">
                  Jumlah Soal
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataSoal.totalData} Soal
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex-1 text-right">
                  Terakhir input
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataSoal.latestInput}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 space-y-3">
          <div className="w-full p-3 py-10 rounded-lg bg-white space-y-5 relative">
            {dashboardModel.dataJenjang.isLoad && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-blue-600"
              >
                <RiLoader5Fill className={"h-14 w-14 animate-spin"} />
              </div>
            )}
            <div
              className="font-bold"
              style={{ fontFamily: "Nunito", fontSize: "18px" }}
            >
              Data jenjang
            </div>
            <div className="flex justify-center">
              <div className="p-5 rounded-full bg-yellow-500 text-blue-600">
                <GiGraduateCap className="h-14 w-14" />
              </div>
            </div>
            <div
              className="px-2"
              style={{ fontSize: "14px" }}
            >
              <div className="flex space-between">
                <div className="flex-1 text-right">
                  Jumlah Jenjang
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataJenjang.totalData} Kategori
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex-1 text-right">
                  Terakhir input
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataJenjang.latestInput}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-3 py-10 rounded-lg bg-white space-y-5 relative">
            {dashboardModel.dataMapel.isLoad && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-blue-600"
              >
                <RiLoader5Fill className={"h-14 w-14 animate-spin"} />
              </div>
            )}
            <div
              className="font-bold"
              style={{ fontFamily: "Nunito", fontSize: "18px" }}
            >
              Data Mapel
            </div>
            <div className="flex justify-center">
              <div className="p-5 rounded-full bg-yellow-500 text-blue-600">
                <GiBookshelf className="h-14 w-14" />
              </div>
            </div>
            <div
              className="px-2"
              style={{ fontSize: "14px" }}
            >
              <div className="flex space-between">
                <div className="flex-1 text-right">
                  Jumlah Mapel
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataMapel.totalData} Mapel
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex-1 text-right">
                  Terakhir input
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataMapel.latestInput}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 space-y-3">
          <div className="w-full p-3 py-10 rounded-lg bg-white space-y-5 relative">
            {dashboardModel.dataMateri.isLoad && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-blue-600"
              >
                <RiLoader5Fill className={"h-14 w-14 animate-spin"} />
              </div>
            )}
            <div
              className="font-bold"
              style={{ fontFamily: "Nunito", fontSize: "18px" }}
            >
              Data Materi
            </div>
            <div className="flex justify-center">
              <div className="p-5 rounded-full bg-yellow-500 text-blue-600">
                <FaPencilRuler className="h-12 w-12" />
              </div>
            </div>
            <div
              className="px-2"
              style={{ fontSize: "14px" }}
            >
              <div className="flex space-between">
                <div className="flex-1 text-right">
                  Jumlah Materi
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataMateri.totalData} Materi
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex-1 text-right">
                  Terakhir input
                </div>
                <div className="px-3">
                  :
                </div>
                <div className="flex-1 text-left">
                  {dashboardModel.dataMateri.latestInput}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}