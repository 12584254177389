import Header from "../../../shared/Header"
import JenjangPage from "../../Jenjang/Pages/JenjangPage"
import MapelPage from "../../Mapel/Pages/MapelPage"
import MateriPage from "../../Materi/Pages/MateriPage"
import FormDataPage from "../../Soal/Pages/FormDataPage"
import SoalPage from "../../Soal/Pages/SoalPage"
import MainMenu from "./MainMenu"
import { Route, Switch, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RiLoader5Fill } from "react-icons/ri"
import ChangePasswordDialog from "../../../shared/ChangePasswordDialog"
import { closeChangePasswordDialog, setIsHomeProcess } from "../../../app/GlobalSlice"
import { changePassword } from "../../../Apis/AdminApi"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"

export default function HomePage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    screenSize,
    isHomeProcess,
    changePasswordDialog
  } = useSelector(state => state.global)

  function onChangePassword(oldPassword, newPassword) {
    dispatch(setIsHomeProcess(true))

    const payload = {
      oldPassword: oldPassword,
      confirmOldPassword: oldPassword,
      newPassword: newPassword
    }

    changePassword(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: _ => {
            dispatch(closeChangePasswordDialog())
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { dispatch(setIsHomeProcess(false)) })

  }

  return (
    <>
      { isHomeProcess && (
        <>
          <div
            className="absolute top-0 left-0 h-full w-full bg-black opacity-50"
            style={{
              zIndex: 998
            }}
          >
          </div>
          <div
            className="absolute h-full w-full flex items-center justify-center text-blue-700"
            style={{
              zIndex: 999
            }}
          >
            <RiLoader5Fill className={"h-16 w-16 animate-spin"} />
          </div>
        </>
      )}

      <ChangePasswordDialog
        isShow={changePasswordDialog.isOpen}
        onClose={() => dispatch(closeChangePasswordDialog())}
        onChangePassword={onChangePassword}
      />

      <div
        className="w-full h-full space-y-5 fixed overflow-auto"
        style={{
          backgroundImage: 'url("/images/bg-bank-soal-2.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: 'cover'
        }}
      >

        <Header />
        <div
          className="w-full pb-10 flex justify-center px-5"
          style={{
            paddingTop: "50px"
          }}
        >
          <div
            className="space-y-5"
            style={{
              width: "100%",
              maxWidth: "1300px" 
            }}
          >
            <Switch>
              <Route path={"/home/soal"}>
                <SoalPage />
              </Route>
              <Route path={"/home/materi"}>
                <MateriPage />
              </Route>
              <Route path={"/home/mapel"}>
                <MapelPage />
              </Route>
              <Route path={"/home/jenjang"}>
                <JenjangPage />
              </Route>
              <Route path={"/home/"}>
                <MainMenu />
              </Route>
            </Switch>

          </div>
        </div>

      </div>
    </>
  )
}
