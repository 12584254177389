import { Menu, Transition } from "@headlessui/react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { RiLogoutCircleFill, RiUserFill } from "react-icons/ri"
import { IoMdLock } from 'react-icons/io'
import { openChangePasswordDialog, setAdmin, setAdminLogout, setIsHomeProcess } from "../app/GlobalSlice"
import { logout } from "../Apis/AdminApi"
import { handleResponse200 } from "../utils/HttpUtils"

function MenuItemBox({
  label,
  isActive,
  onClick
}) {
  return (
    <button
      className={
        "h-full border-t-2 px-4 flex items-center justify-center " +
        (isActive ? "  border-blue-500 bg-blue-100 " : "  border-transparent hover:bg-blue-100 ")
      }
      style={{
        width: '100px',
        fontFamily: 'Helvetica',
        fontSize: '13px'
      }}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

function ProfileBox() {

}

export default function Header() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { homeActivePage, admin } = useSelector(state => state.global)

  const [headerModel, setHeaderModel] = useState({
    activeMenu: 0,
    menus: [
      {
        id: 0,
        label: 'Dashboard',
        url: '/home/'
      },
      {
        id: 1,
        label: 'Jenjang',
        url: '/home/jenjang'
      },
      {
        id: 2,
        label: 'Mapel',
        url: '/home/mapel'
      },
      {
        id: 3,
        label: 'Materi',
        url: '/home/materi'
      },
      {
        id: 4,
        label: 'Soal',
        url: '/home/soal'
      },
    ],
    profileMenu: {
      isOpen: false
    }
  })

  useEffect(() => {
    if (homeActivePage === 'dashboard') {
      setHeaderModel({ ...headerModel, activeMenu: 0 })
    }
    else if (homeActivePage === 'jenjang') {
      setHeaderModel({ ...headerModel, activeMenu: 1 })
    }
    else if (homeActivePage === 'mapel') {
      setHeaderModel({ ...headerModel, activeMenu: 2 })
    }
    else if (homeActivePage === 'materi') {
      setHeaderModel({ ...headerModel, activeMenu: 3 })
    }
    else if (homeActivePage === 'soal') {
      setHeaderModel({ ...headerModel, activeMenu: 4 })
    }
    else {
      setHeaderModel({ ...headerModel, activeMenu: null })
    }
  }, [homeActivePage])

  const toggleProfileMenu = (m) => ({ ...m, profileMenu: { ...m.profileMenu, isOpen: !m.profileMenu.isOpen } })

  const outsideWrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (useRef.current && !useRef.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [outsideWrapperRef])

  return (
    <div
      ref={outsideWrapperRef}
      className="w-full flex justify-end bg-white border-b-2 border-blue-700 fixed"
      style={{
        height: '50px',
        zIndex: "10"
      }}
    >
      <div className="w-full flex justify-center">
        <div
          className="w-full flex justify-between"
          style={{ width: "1300px" }}
        >
          <div className="h-full flex items-center pt-2">
            {headerModel.menus.map(m => (
              <MenuItemBox
                label={m.label}
                isActive={m.id === headerModel.activeMenu}
                // onClick={() => setHeaderModel({ ...headerModel, activeMenu: m.id })}
                onClick={() => history.push(m.url)}
              />
            ))}
          </div>
          <div className="h-full flex items-center pt-2">
            <div
              className="h-full flex items-center justify-center relative"
            >
              <Menu>
                {({ open }) => (
                  <>
                    <Menu.Button
                      className={
                        "h-full border-t-2 px-4 flex items-center justify-center border-blue-500 bg-blue-100 "
                      }
                      style={{
                        width: '100px',
                        fontFamily: 'Helvetica',
                        fontSize: '13px'
                      }}
                    >
                      Profil
                    </Menu.Button>
                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute top-10 right-0 bg-white rounded-md shadow-lg outline-none divide-y divide-gray-300"
                        style={{
                          width: "250px",
                          zIndex: "10"
                        }}
                      >
                        <div>
                          <div className="py-5 flex flex-col items-center justify-center">
                            <div className="p-5 rounded-full bg-yellow-500">
                              <RiUserFill className="h-16 w-16" color="white" />
                            </div>
                          </div>
                          <div
                            className="pb-5 space-y-3"
                            style={{
                              fontFamily: 'Lato',
                              fontSize: '14px'
                            }}
                          >
                            <div className="px-5 text-left">
                              <div>
                                Kode
                              </div>
                              <div className="px-3">
                                {admin.kodeAdmin}
                              </div>
                            </div>
                            <div className="px-5 text-left">
                              <div>
                                Nama
                              </div>
                              <div className="px-3">
                                {admin.namaAdmin}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-blue-100 text-gray-900"
                                  : "text-gray-700"
                                  } flex items-center w-full px-4 py-2 text-sm leading-5 text-left space-x-2`}
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: '16px'
                                }}
                                onClick={() => dispatch(openChangePasswordDialog())}
                              >
                                <IoMdLock className="h-6 w-6" />
                                <label>Ubah password</label>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active
                                  ? "bg-blue-100 text-gray-900"
                                  : "text-gray-700"
                                  } flex items-center w-full px-4 py-2 text-sm leading-5 text-left space-x-2`}
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: '16px'
                                }}
                                onClick={() => {
                                  dispatch(setIsHomeProcess(true))
                                  onLogOut(() => {
                                    dispatch(setIsHomeProcess(false))
                                    dispatch(
                                      setAdminLogout()
                                    )
                                  })
                                }}
                              >
                                <RiLogoutCircleFill className="h-6 w-6" />
                                <label>Log out</label>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function onLogOut(onSuccess) {
  logout()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: response => {
          console.log(response);
          onSuccess()
        },
        onRecovFailure: err => console.log(err),
        onUnAuth: err => console.log(err),
        onTechnicalError: err => console.log(err)
      })
    })
    .catch(error => { })
    .finally(() => { })

}