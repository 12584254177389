import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
  getEmail,
  initModel,
  updateEmail,
  getPassword,
  updatePassword,
  isPasswordOpen,
  togglePassword,
  isLoading,
  setIsLoading
} from '../Models/Model'
import { IoMdMail, IoMdLock, IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { Transition } from '@headlessui/react';
import { login } from '../Api/Api'
import { useDispatch, useSelector } from "react-redux";
import { setAdmin } from "../../../app/GlobalSlice";
import { isDesktop, isTab, isPhone } from "../../../utils/PlatformHelper"
import { handleResponse200 } from '../../../utils/HttpUtils'
import { notifyError } from "../../../utils/Notification"
import { RiLoader5Fill } from "react-icons/ri";

const onLogin = (model, setModel, onSuccess) => {

  setModel(setIsLoading(model, true))

  const payload = {
    email: getEmail(model),
    password: getPassword(model)
  }

  login(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: adminProfile => onSuccess(adminProfile),
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: _ => null,
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(err => { })
    .finally(() => setModel(setIsLoading(model, false)))
}

const LoginBox = ({ model, setModel }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <div
      className="py-5 px-10 rounded-lg bg-white space-y-8 relative shadow-lg"
      style={{
        width: "450px",
        height: "380px"
      }}
    >
      <div className="space-y-2">
        <div className="py-2 flex flex-1 flex-col text-left space-y-1">
          <label className="tracking-wide text-black" style={{ fontSize: "14px" }}>Masukan email</label>
          <div
            className="py-1 h-full flex"
          >
            <label
              className="
              px-3 py-3 h-full flex items-center border-t border-l border-b border-gray-200 rounded-tl-md rounded-bl-md bg-white outline-none bg-gray-100
            "
            >
              <IoMdMail className="h-6 w-6" />
            </label>
            <input
              type="text"
              className="p-2 py-3 w-full border-t border-r border-b border-gray-200 bg-white rounded-tr-md rounded-br-md outline-none bg-gray-100"
              value={getEmail(model)}
              onChange={e => setModel(updateEmail(model, e.target.value))}
              disabled={isLoading(model)}
            />
          </div>
        </div>

        <div className="py-2 flex flex-1 flex-col text-left space-y-1">
          <label className="tracking-wide text-black" style={{ fontSize: "14px" }}>Masukan password</label>
          <div
            className="py-1 h-full flex"
          >
            <label
              className="
              px-3 py-3 h-full flex items-center border-t border-l border-b border-gray-200 rounded-tl-md rounded-bl-md bg-white outline-none bg-gray-100
            "
            >
              <IoMdLock className="h-6 w-6" />
            </label>
            <input
              type={isPasswordOpen(model) ? "text" : "password"}
              className="p-2 py-3 w-full border-t border-b border-gray-200 bg-white outline-none bg-gray-100"
              value={getPassword(model)}
              onChange={e => setModel(updatePassword(model, e.target.value))}
              disabled={isLoading(model)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onLogin(
                    model,
                    setModel,
                    (cred) => {
                      history.push("/home")
                      dispatch(
                        setAdmin({
                          kode: cred.kodeAdmin,
                          nama: cred.namaAdmin
                        })
                      )
                    }
                  )
                }
              }}
            />
            <button
              className="
              px-3 py-3 h-full flex items-center border-t border-r border-b border-gray-200 rounded-tr-md rounded-br-md bg-white outline-none bg-gray-100
            "
              onClick={() => setModel(togglePassword(model))}
            >
              {isPasswordOpen(model) ?
                <IoMdEye className="h-6 w-6" color="blue" />
                :
                <IoMdEyeOff className="h-6 w-6" color="blue" />
              }
            </button>
          </div>
        </div>
      </div>

      <div>
        <button
          className="w-full rounded-full bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 flex justify-center "
          onClick={() => {
            onLogin(
              model,
              setModel,
              (cred) => {
                history.push("/home")
                dispatch(
                  setAdmin({
                    kode: cred.kodeAdmin,
                    nama: cred.namaAdmin
                  })
                )
              }
            )
          }}
        >
          {
            isLoading(model) ?
              (<RiLoader5Fill className={"h-6 w-6 animate-spin"} />)
              :
              (<label>Masuk</label>)
          }
        </button>
      </div>

    </div>
  )
}

export default function LoginPages() {

  const [model, setModel] = useState(initModel)

  const [showWelcome, setShowWelcome] = useState(false)

  const { screenSize } = useSelector(state => state.global)

  useEffect(() => {
    setShowWelcome(true)
    return () => {
      setShowWelcome(false)
    }
  }, [])

  return (
    <div
      className="w-full h-full flex pt-32 justify-center"
      style={{
        backgroundImage: 'url("/images/bg-bank-soal-2.jpg")',
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover'
      }}
    >
      <div
        className={
          "flex justify-center " +
          (isDesktop(screenSize.width) ? " space-x-5 " : "") +
          (isPhone(screenSize.width) ? " px-5 " : "")
        }
        style={{
          width: isDesktop(screenSize.width) ? "1200px" : "100%"
        }}
      >
        <LoginBox model={model} setModel={setModel} />
      </div>

    </div>
  )
}

