import { Dialog, Transition } from "@headlessui/react"
import { useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { useEffect, useRef, useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"
import Select from "react-select"

export default function FormDataDialog({
  isShow,
  selectedData,
  isSaving,
  onSave,
  onClose,
  mode,
  jenjangIdLabel,
  mapelIdLabel
}) {

  const initForm = {
    kodeMateri: null,
    namaMateri: '',
    selectedJenjang: null,
    selectedMapel: null,
    jenjangOpts: [],
    mapelOpts: []
  }

  const [form, setForm] = useState(initForm)

  const namaInputRef = useRef()

  useEffect(() => {

    if (isShow) {
      setForm({
        ...form,
        kodeMateri: selectedData ? selectedData.kodeMateri : null,
        namaMateri: selectedData ? selectedData.namaMateri : '',
        jenjangOpts: jenjangIdLabel.map(jil => ({ value: jil.id, label: jil.label })),
        mapelOpts: mapelIdLabel.map(mil => ({ value: mil.id, label: mil.label })),
        selectedJenjang: selectedData ? ({ value: selectedData.jenjang.id, label: selectedData.jenjang.label }) : null,
        selectedMapel: selectedData ? ({ value: selectedData.mapel.id, label: selectedData.mapel.label }) : null
      })
    }
    else {
      setForm(initForm)
    }
  }, [isShow])

  const { screenSize } = useSelector(state => state.global)

  return (
    <Transition
      appear
      show={isShow}
    >
      <input
        ref={namaInputRef}
        className="w-full p-2 rounded-md border border-gray-300 outline-none"
        placeholder="Cth. Kelas VII, Kelas VIII, Kelas XI IPA"
      />
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "90"
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-1 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="px-2 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500 outline-none"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 mb-2 px-5 flex flex-col space-y-3  space-y-5"
                >
                  {
                    !isSaving ?
                      (
                        <>
                          {selectedData && (
                            <div className="space-y-1.5">
                              <div className="text-left">
                                Kode Materi
                              </div>
                              <div>
                                <input
                                  className="w-full p-2 rounded-md border border-gray-300 outline-none"
                                  value={form.kodeMateri}
                                  readOnly
                                />
                              </div>
                            </div>
                          )}
                          <div className="space-y-1.5">
                            <div className="text-left">
                              Jenjang
                            </div>
                            <div>
                              <Select
                                value={form.selectedJenjang}
                                options={form.jenjangOpts}
                                menuPlacement="auto"
                                className="w-full border border-gray-200 rounded-md"
                                onChange={(value) => {
                                  setForm({ ...form, selectedJenjang: value })
                                }}
                              />
                            </div>
                          </div>
                          <div className="space-y-1.5">
                            <div className="text-left">
                              Mata Pelajaran
                            </div>
                            <div>
                              <Select
                                value={form.selectedMapel}
                                options={form.mapelOpts}
                                menuPlacement="auto"
                                className="w-full border border-gray-200 rounded-md"
                                onChange={(value) => {
                                  setForm({ ...form, selectedMapel: value })
                                }}
                              />
                            </div>
                          </div>
                          <div className="space-y-1.5">
                            <div className="text-left">
                              Nama Materi
                            </div>
                            <div>
                              <input
                                className="w-full p-2 rounded-md border border-gray-300 outline-none"
                                placeholder="Cth. Aljabar Linear, Geometri, Neraca Akuntansi"
                                value={form.namaMateri}
                                onChange={e => setForm({ ...form, namaMateri: e.target.value })}
                                readOnly={mode === 'detail'}
                              />
                            </div>
                          </div>
                          <div className="py-2 flex justify-center space-x-5">
                            {mode !== 'detail' && (
                              <>
                                <button
                                  className="rounded-full py-1 px-8 bg-red-500 hover:bg-red-700 text-white"
                                  onClick={onClose}
                                >
                                  Batal
                                </button>
                                <button
                                  className="rounded-full py-1 px-5 bg-blue-500 hover:bg-blue-700 text-white"
                                  onClick={() => {
                                    if (form.selectedJenjang && form.selectedMapel && form.namaMateri && form.namaMateri !== '') {
                                      onSave(form.selectedJenjang.value, form.selectedMapel.value, form.namaMateri)
                                    }
                                  }}
                                >
                                  Simpan
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )
                      :
                      (
                        <div
                          className="flex items-center justify-center text-blue-600 bg-white"
                          style={{ minHeight: "50px" }}
                        >
                          <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                        </div>
                      )
                  }
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}