import { Transition } from "@headlessui/react"

import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsTrash } from "react-icons/bs"

import { format } from "date-fns"
import DeleteDataDialog from "./DeleteDataDialog";
import React, { useEffect, useState } from "react";
import Select from "react-select"
import {
  closeDeleteDialog,
  closeFormDialog,
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage,
  initModel,
  openAddFormPage,
  openDeleteDialog,
  openDetailFormPage,
  openEditFormPage,
  resetSearchText,
  setData,
  setDataSummary,
  setFinishRefetchData,
  setFinishRefetchDataSummary,
  setIsLoadData,
  setJenjangIdLabel,
  setMapelIdLabel,
  setSearchText,
  setSortFromNewestData,
  setSortFromOldestData,
  setStartRefetchData,
  setStartRefetchDataSummary,
  setSelectedKodeJenjang,
  setSelectedKodeMapel,
  setMateriIdLabel,
  setSelectedKodeMateri
} from "../Models/Model";
import {
  createData,
  deleteData,
  getData,
  getDataSummary,
  getJenjangIdLabel,
  getMapelIdLabel,
  getMateriIdLabel,
  updateData,
  updateDataActivation
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import FormDataPage from "./FormDataPage";
import { useDispatch } from "react-redux";
import { setHomeActivePage } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";

function Latex(props) {
  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      node.current
    ]);
  }
  return (
    <div ref={node}>
      {props.children}
    </div>
  );
}

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  kodeSoal,
  soal,
  namaMateri,
  jenjang,
  mapel,
  onDelete,
  onViewDetail,
  onEdit
}) {

  return (
    <div
      className="py-1 w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "130px"
        }}
      >
        {kodeSoal}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "44%",
          minWidth: "200px"
        }}
      > 
        <div className="overflow-y-auto" style={{ maxHeight: "200px" }} dangerouslySetInnerHTML={{ __html: soal }} />
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "44%",
          minWidth: "150px"
        }}
      >
        {namaMateri}
      </div>
      <div 
        className="flex px-2 items-center justify-left group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "150px" 
        }}
      >
        {mapel}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "15%",
          minWidth: "150px" 
        }}
      >
        {jenjang}
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }}
      >
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onViewDetail}
        >
          <RiFilePaperLine className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onEdit}
        >
          <RiEdit2Line className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onDelete}
        >
          <BsTrash className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100"
      style={{
        fontSize: "15px"
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "130px"
        }}
      >
        Kode Soal
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "44%",
          minWidth: "200px"
        }}
      >
        Soal
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "44%",
          minWidth: "150px"
        }}
      >
        Materi
      </div>
      <div 
        className="flex px-2 items-center justify-left group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "150px"
        }}
      >
        Mapel
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "15%",
          minWidth: "150px" 
        }}
      >
        Jenjang
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }}
      >
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  return (
    <>
      <Latex>
        <div className="overflow-x-auto bg-white">
          <HeaderTable />
          {
            isLoadData ?
              (<div
                className="flex items-center justify-center text-blue-600 bg-white"
                style={{ minHeight: "50px" }}
              >
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>)
              :
              datas.length > 0 ?
                (<div>
                  {datas.map((d, idx) => (
                    <ItemBox
                      no={idx + 1}
                      kodeSoal={d.kodeSoal}
                      soal={d.soal}
                      namaMateri={d.namaMateri}
                      jenjang={d.namaJenjang}
                      mapel={d.namaMapel}
                      onViewDetail={() => setRootModel(openDetailFormPage(rootModel, d))}
                      onEdit={() => setRootModel(openEditFormPage(rootModel, d))}
                      onDelete={() => setRootModel(openDeleteDialog(rootModel, d))}
                    />
                  ))}
                </div>)
                :
                (<div className="py-2 bg-white">
                  Tidak ada data
                </div>)
          }
        </div>
        <DataTableFooter
          pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
          showCount={rootModel.dataList.length}
          sortMode={dataPropertOrdToStr(rootModel)}
          searchText={rootModel.searchHeader.searchText}
          hasNextPage={hasNextPage(rootModel)}
          hasPrevPage={hasPrevPage(rootModel)}
          onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
          onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
        />
      </Latex>
    </>
  )
}

function FilterBox({
  rootModel,
  setRootModel,
  jenjangIdLabel,
  mapelIdLabel,
  materiIdLabel
}) {
  const history = useHistory()

  const [filterModel, setFilterModel] = useState({
    selectedJenjang:
      rootModel.dataProperties.selectedKodeMapel ?
        jenjangIdLabel.map(jil => ({ value: jil.id, label: jil.label })).find(jil => jil.value === rootModel.dataProperties.selectedKodeJenjang)
        :
        { value: null, label: 'Semua Jenjang' },
    selectedMapel:
      rootModel.dataProperties.selectedKodeMapel ?
        mapelIdLabel.map(mil => ({ value: mil.id, label: mil.label })).find(mil => mil.value === rootModel.dataProperties.selectedKodeMapel)
        :
        { value: null, label: 'Semua Materi' },
    selectedMateri:
      rootModel.dataProperties.selectedKodeMateri ?
        materiIdLabel.map(mil => ({ value: mil.id, label: mil.label })).find(mil => mil.value === rootModel.dataProperties.selectedKodeMateri)
        :
        { value: null, label: 'Semua Materi' },
    jenjangOpts: [{ value: null, label: 'Semua Jenjang' }, ...jenjangIdLabel.map(jil => ({ value: jil.id, label: jil.label }))],
    mapelOpts: [{ value: null, label: 'Semua Mapel' }, ...mapelIdLabel.map(mil => ({ value: mil.id, label: mil.label }))],
    materiOpts: [{ value: null, label: 'Semua Materi' }, ...materiIdLabel.map(mil => ({ value: mil.id, label: mil.label }))],
    isLoadMateri: false
  })

  useEffect(() => {
    setFilterModel(latestM => ({ ...latestM, mapelOpts: [{ value: null, label: 'Semua Mapel' }, ...mapelIdLabel.map(mil => ({ value: mil.id, label: mil.label }))] }))
  }, [mapelIdLabel])

  useEffect(() => {
    setFilterModel(latestM => ({ ...latestM, jenjangOpts: [{ value: null, label: 'Semua Jenjang' }, ...jenjangIdLabel.map(jil => ({ value: jil.id, label: jil.label }))] }))
  }, [jenjangIdLabel])

  useEffect(() => {
    setFilterModel(latestM => ({ ...latestM, materiOpts: [{ value: null, label: 'Semua Materi' }, ...materiIdLabel.map(mil => ({ value: mil.id, label: mil.label }))] }))
  }, [materiIdLabel])

  // useEffect(() => {
  //   setFilterModel({ ...filterModel, selectedMateri: { value: null, label: 'Semua Materi' } })
  // }, [filterModel.selectedJenjang, filterModel.selectedMapel])

  function onSelectMateri() {
    setFilterModel({ ...filterModel, isLoadMateri: true })

    getMateriIdLabel(filterModel.selectedJenjang.value, filterModel.selectedMapel.value)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setMateriIdLabel(latestM, payload)
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { setFilterModel({ ...filterModel, isLoadMateri: false }) })
  }


  function onApplied() {
    setRootModel(
      setSelectedKodeJenjang(
        setSelectedKodeMapel(
          setSelectedKodeMateri(
            rootModel,
            filterModel.selectedMateri.value
          ),
          filterModel.selectedMapel.value
        ),
        filterModel.selectedJenjang.value
      )
    )
  }

  return (
    <div className="p-2 rounded-md bg-white flex space-x-2">
      <Select
        value={filterModel.selectedJenjang}
        options={filterModel.jenjangOpts}
        menuPlacement="auto"
        className="w-64 border border-gray-200 rounded-md text-left"
        onChange={(value) => {
          setFilterModel({
            ...filterModel,
            selectedJenjang: value,
            selectedMateri: { value: null, label: 'Semua Materi' }
          })
        }}
      />
      <Select
        value={filterModel.selectedMapel}
        options={filterModel.mapelOpts}
        menuPlacement="auto"
        className="w-64 border border-gray-200 rounded-md text-left"
        onChange={(value) => {
          setFilterModel({
            ...filterModel,
            selectedMapel: value,
            selectedMateri: { value: null, label: 'Semua Materi' }
          })
        }}
      />
      <Select
        value={filterModel.selectedMateri}
        options={filterModel.materiOpts}
        isLoading={filterModel.isLoadMateri}
        onFocus={onSelectMateri}
        menuPlacement="auto"
        className="w-64 border border-gray-200 rounded-md text-left"
        onChange={(value) => {
          setFilterModel({ ...filterModel, selectedMateri: value })
        }}
      />
      <button
        className="p-2 px-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg text-white rounded-sm"
        onClick={onApplied}
      >
        Aplikasikan
      </button>
    </div>
  )

}

function SummaryBox({
  rootModel,
  setRootModel,
  totalData,
  totalDataAktif,
  terakhirInput,
  onOpenAddForm
}) {

  const [headerModel, setHeaderModel] = useState({
    selectedMenu: null, //search, filter, sort,
  })

  const openFilterMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'filter' ? null : 'filter' })
  const openSortMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'sort' ? null : 'sort' })
  const openSearchMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'search' ? null : 'search' })
  const closeMenu = (m) => ({ ...m, selectedMenu: null })

  return (
    <>
      <Transition
        show={headerModel.selectedMenu === 'search'}
      >
        <div
          className="bg-white rounded-lg shadow-lg flex items-center"
          style={{ height: "50px" }}
        >
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setHeaderModel(closeMenu(headerModel))}
            >
              <RiArrowLeftLine className="h-6 w-6" />
            </button>
          </div>
          <div className="flex-1 h-full py-2 text-gray-700">
            <input
              autoFocus
              className="w-full h-full outline-none italic"
              onChange={e => setRootModel(setSearchText(rootModel, e.target.value))}
              value={rootModel.searchHeader.searchText}
              placeholder={"Cari kode Soal, Materi, atau Mata Pelajaran"}
            />
          </div>
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setRootModel(resetSearchText(rootModel))}
            >
              <RiCloseFill className="h-6 w-6" />
            </button>
          </div>
        </div>
      </Transition>
      <Transition
        show={headerModel.selectedMenu !== 'search'}
      >
        <div className="">
          <div
            className="flex items-center justify-between bg-gray-200 p-3 py-7 rounded-sm"
            style={{ height: "50px" }}
          >
            <div>
              <div
                className="text-left font-bold"
                style={{
                  fontSize: "18px",
                  fontFamily: "Nunito"
                }}
              >
                Data Soal
              </div>
            </div>
            <div
              className="flex space-x-2"
            >
              <div className="flex items-center">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:text-blue-700 text-black "
                  }
                  onClick={() => setHeaderModel(openSearchMenu(headerModel))}
                >
                  <RiSearchLine className="h-5 w-5" />
                </button>
              </div>
              <div className="flex items-center relative">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                  }
                  onClick={() => setHeaderModel(openSortMenu(headerModel))}
                >
                  <MdSort className="h-5 w-5" />
                </button>
                <Transition
                  show={headerModel.selectedMenu === 'sort'}
                  enter="duration-350"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="absolute top-10"
                  >
                    <div
                      className="bg-white z-10 rounded-lg shadow-lg text-left"
                      style={{
                        width: "200px",
                        marginLeft: "-200px"
                      }}
                    >
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'newestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromNewestData(rootModel))
                          }}
                        >
                          Dari terbaru
                      </button>
                      </div>
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'oldestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromOldestData(rootModel))
                          }}
                        >
                          Dari terlama
                      </button>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
              <button
                className={
                  "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                }
                onClick={onOpenAddForm}
              >
                <RiAddLine className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div
            className="flex px-5 py-3 space-x-12 bg-white rounded-bl-lg rounded-br-lg"
            style={{
              fontSize: "15px"
            }}
          >
            <div className="text-left">
              <div>
                Total Data
            </div>
              <div>
                {totalData}
              </div>
            </div>
            <div className="text-left">
              <div>
                Total Data Aktif
            </div>
              <div>
                {totalDataAktif}
              </div>
            </div>
            <div className="text-left">
              <div>
                Terakhir input
            </div>
              <div>
                {terakhirInput ? format(new Date(terakhirInput), "dd/MM/yyyy") : 'tidak ada data'}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export default function SoalPage() {

  const dispatch = useDispatch()
  const history = useHistory()

  const [rootModel, setRootModel] = useState(initModel)

  useEffect(() => {
    onGetDataSummary(rootModel, setRootModel, history)
    onGetJenjangIdLabel(rootModel, setRootModel, history)
    onGetMapelIdLabel(rootModel, setRootModel, history)

    dispatch(setHomeActivePage('soal'))
  }, [])

  useEffect(() => {
    if (rootModel.activeSubPage === 'home' || rootModel.activeSubPage === 'delete') {
      onGetData(rootModel, setRootModel, history)
    }
  }, [rootModel.activeSubPage, rootModel.searchHeader.searchText, rootModel.dataProperties])

  useEffect(() => {
    if (rootModel.refetchData) {
      onGetData(rootModel, setRootModel, history)
    }
  }, [rootModel.refetchData])

  useEffect(() => {
    if (rootModel.refetchDataSummary) {
      onGetDataSummary(rootModel, setRootModel, history)
    }
  }, [rootModel.refetchDataSummary])

  return (
    <div className="space-y-3">
      <FormDataPage
        isShow={rootModel.activeSubPage === 'form'}
        rootModel={rootModel}
        setRootModel={setRootModel}
        selectedQuestionDetail={rootModel.form.selectedData}
      />
      <DeleteDataDialog
        isShow={rootModel.activeSubPage === 'delete'}
        selectedData={rootModel.deleteDialog.selectedData}
        onDelete={() => onDeleteData(rootModel, setRootModel, history)}
        onClose={() => setRootModel(closeDeleteDialog(rootModel))}
      />
      <Transition
        show={rootModel.activeSubPage === 'home' || rootModel.activeSubPage === 'delete'}
        enter="transform transition duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition ease-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="space-y-3">
          <SummaryBox
            rootModel={rootModel}
            setRootModel={setRootModel}
            totalData={rootModel.dataSummary.totalData}
            totalDataAktif={rootModel.dataSummary.totalDataAktif}
            terakhirInput={rootModel.dataSummary.penginputanTerakhir}
            onOpenAddForm={() => setRootModel(openAddFormPage(rootModel))}
          />
          <FilterBox
            rootModel={rootModel}
            setRootModel={setRootModel}
            jenjangIdLabel={rootModel.jenjangIdLabelList}
            mapelIdLabel={rootModel.mapelIdLabelList}
            materiIdLabel={rootModel.materiIdLabelList}
          />
          <DataTable
            isLoadData={rootModel.isLoadData}
            rootModel={rootModel}
            setRootModel={setRootModel}
            datas={rootModel.dataList}
          />
        </div>
      </Transition>
    </div>
  )
}

function onSaveData(kodeJenjang, kodeMapel, namaMateri, rootModel, setRootModel, history) {

  setRootModel({
    ...rootModel,
    form: {
      ...rootModel.form,
      isSaving: true
    }
  })

  if (rootModel.form.mode === 'add') {
    const payload = {
      kodeJenjang: kodeJenjang,
      kodeMapel: kodeMapel,
      namaMateri: namaMateri
    }

    createData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeFormDialog(latestM)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => ({
          ...latestM,
          form: {
            ...latestM.form,
            isSaving: false
          }
        }))
      })
  }
  else if (rootModel.form.mode === 'edit') {
    const payload = {
      kodeMateri: rootModel.form.selectedData.kodeMateri,
      kodeJenjang: kodeJenjang,
      kodeMapel: kodeMapel,
      namaMateri: namaMateri
    }

    updateData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeFormDialog(latestM)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => ({
          ...latestM,
          form: {
            ...latestM.form,
            isSaving: false
          }
        }))
      })
  }
}

function onGetJenjangIdLabel(rootModel, setRootModel, history) {
  getJenjangIdLabel()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setJenjangIdLabel(latestM, payload)
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => { })
}

function onGetMapelIdLabel(rootModel, setRootModel, history) {
  getMapelIdLabel()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setMapelIdLabel(latestM, payload)
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => { })
}

function onGetMateriIdLabel(rootModel, setRootModel, history) {
  getMateriIdLabel(rootModel.dataProperties.selectedKodeJenjang, rootModel.dataProperties.selectedKodeMapel)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setMateriIdLabel(latestM, payload)
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => { })
}

function onGetData(rootModel, setRootModel, history) {

  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    kodeJenjang: rootModel.dataProperties.selectedKodeJenjang,
    kodeMapel: rootModel.dataProperties.selectedKodeMapel,
    kodeMateri: rootModel.dataProperties.selectedKodeMateri
  }

  setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(setFinishRefetchData(latestM), false))
    })

}

function onGetDataSummary(rootModel, setRootModel, history) {
  getDataSummary()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setDataSummary(latestM, payload.totalData, payload.totalDataAktif, payload.latestInput))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setFinishRefetchDataSummary(latestM))
    })
}

function onDeleteData(rootModel, setRootModel, history) {
  const kodeData = rootModel.deleteDialog.selectedData.kodeSoal

  setRootModel(latestM => ({
    ...rootModel,
    deleteDialog: {
      ...rootModel.deleteDialog,
      isDeleting: true
    }
  }))

  deleteData(kodeData)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setStartRefetchData(
              setStartRefetchDataSummary(
                closeDeleteDialog(latestM)
              )
            )
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => ({
        ...latestM,
        deleteDialog: {
          ...latestM.deleteDialog,
          isDeleting: false
        }
      }))
    })
}
