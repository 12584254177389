import axios from "axios"

export const getJenjangIdLabel = async () => {
  const finalUrl = window._HOST + "admin/jenjang/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getMapelIdLabel = async () => {
  const finalUrl = window._HOST + "admin/mapel/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getMateriIdLabel = async (kodeJenjang, kodeMapel) => {
  const finalUrl =
    window._HOST + "admin/materi/id-label?" + (kodeJenjang ? "kode-jenjang=" + kodeJenjang + "&" : "") + (kodeMapel ? "kode-mapel=" + kodeMapel : "")

  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/soal/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/soal/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDataDetail = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/soal?kode-soal=" + kodeData

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/soal-convensional"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/soal"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/mapel-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/soal?kode-soal=" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}
