import { Dialog, Transition } from "@headlessui/react"
import { useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { useState } from "react"
import { IoMdLock, IoMdEye, IoMdEyeOff } from 'react-icons/io'

export default function ChangePasswordDialog({
  isShow,
  onClose,
  onChangePassword
}) {

  const { screenSize } = useSelector(state => state.global)

  const [form, setForm] = useState({
    oldPassword: {
      text: '',
      showPassword: false
    },
    newPassword: {
      text: '',
      showPassword: false
    }
  })

  const toggleShowOldPassword = () => {
    setForm({ ...form, oldPassword: { ...form.oldPassword, showPassword: !form.oldPassword.showPassword } })
  }

  const toggleShowNewPassword = () => {
    setForm({ ...form, newPassword: { ...form.newPassword, showPassword: !form.newPassword.showPassword } })
  }

  return (
    <Transition
      appear
      show={isShow}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "90"
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-1 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="px-2 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500 outline-none"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 mb-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                >

                  <div className="py-2 flex flex-1 flex-col text-left space-y-1">
                    <label className="tracking-wide text-black" style={{ fontSize: "14px" }}>Password lama anda</label>
                    <div
                      className="py-1 h-full flex"
                    >
                      <label
                        className="
                        px-3 py-3 h-full flex items-center border-t border-l border-b border-gray-200 rounded-tl-md rounded-bl-md bg-white outline-none bg-gray-100
                      "
                      >
                        <IoMdLock className="h-6 w-6" />
                      </label>
                      <input
                        type={form.oldPassword.showPassword ? "text" : "password"}
                        className="p-2 py-3 w-full border-t border-b border-gray-200 bg-white outline-none bg-gray-100"
                        value={form.oldPassword.text}
                        onChange={e => setForm({ ...form, oldPassword: { ...form.oldPassword, text: e.target.value } })}
                      />
                      <button
                        className="
                        px-3 py-3 h-full flex items-center border-t border-r border-b border-gray-200 rounded-tr-md rounded-br-md bg-white outline-none bg-gray-100
                      "
                        onClick={toggleShowOldPassword}
                      >
                        {form.oldPassword.showPassword ?
                          <IoMdEye className="h-6 w-6" color="blue" />
                          :
                          <IoMdEyeOff className="h-6 w-6" color="blue" />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="py-2 flex flex-1 flex-col text-left space-y-1">
                    <label className="tracking-wide text-black" style={{ fontSize: "14px" }}>Password baru anda</label>
                    <div
                      className="py-1 h-full flex"
                    >
                      <label
                        className="
                        px-3 py-3 h-full flex items-center border-t border-l border-b border-gray-200 rounded-tl-md rounded-bl-md bg-white outline-none bg-gray-100
                      "
                      >
                        <IoMdLock className="h-6 w-6" />
                      </label>
                      <input
                        type={form.newPassword.showPassword ? "text" : "password"}
                        className="p-2 py-3 w-full border-t border-b border-gray-200 bg-white outline-none bg-gray-100"
                        value={form.newPassword.text}
                        onChange={e => setForm({ ...form, newPassword: { ...form.newPassword, text: e.target.value } })}
                      />
                      <button
                        className="
                        px-3 py-3 h-full flex items-center border-t border-r border-b border-gray-200 rounded-tr-md rounded-br-md bg-white outline-none bg-gray-100
                      "
                        onClick={toggleShowNewPassword}
                      >
                        {form.newPassword.showPassword ?
                          <IoMdEye className="h-6 w-6" color="blue" />
                          :
                          <IoMdEyeOff className="h-6 w-6" color="blue" />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="py-2 flex justify-center space-x-5">
                    <button
                      className="rounded-full py-1 px-8 bg-red-500 hover:bg-red-700 text-white"
                      onClick={onClose}
                    >
                      Batal
                    </button>
                    <button
                      className="rounded-full py-1 px-5 bg-blue-500 hover:bg-blue-700 text-white"
                      onClick={() => onChangePassword(form.oldPassword.text, form.newPassword.text)}
                    >
                      Ubah password
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}