import { Transition } from "@headlessui/react";
import { RiArrowLeftLine, RiCloseFill, RiLoader5Fill } from "react-icons/ri";
import { openHomePage, setFinishSavingData, setJenjangIdLabel, setMapelIdLabel, setMateriIdLabel, setStartRefetchData, setStartRefetchDataSummary, setStartSavingData } from "../Models/Model"
import { Editor } from '@tinymce/tinymce-react'
import { createRef, useEffect, useRef, useState, ReactDOM } from "react";
import { BiTrash } from "react-icons/bi";
import { createData, getDataDetail, getJenjangIdLabel, getMapelIdLabel, getMateriIdLabel, updateData } from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils";
import Select from "react-select"
import { AiOutlinePlus } from "react-icons/ai";
import SolutionBox from "./SolutionBox"
import { format } from "date-fns"
import { useDispatch } from "react-redux";
import { setIsHomeProcess } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";

function QuestionChoiceBox({
  choice,
  extRef,
  onLabelChange,
  onRemoveChoice,
  isRemovable,
  onInitEditor
}) {

  return (
    <div
      className="py-3 pb-5 space-y-3 border-t border-gray-300"
    >
      <div className="">
        <div className="text-left italic">
          Label Pilihan Jawaban
        </div>
        <div>
          <input
            className="w-full py-2 border-b outline-none"
            placeholder="Contoh A atau B atau C"
            value={choice.label}
            onChange={e => onLabelChange(choice.code, e.target.value)}
          />
        </div>
      </div>
      <div className="space-y-2">
        <div className="text-left italic">
          Jawaban
        </div>
        <div>
          <Editor
            // apiKey="xh6ky1k77whs74aqd1wac0wzxmpdixv4hdoqw7bco18oo214"
            tinymceScriptSrc="/ext_js/tinymce/tinymce.min.js"
            onInit={(evt, editor) => { console.log('its init'); extRef.current = editor; onInitEditor(evt) }}
            initialValue={choice.answer}
            init={{
              license_key: 'gpl',
              height: 200,
              menubar: false,
              external_plugins: { 'mathjax': '/ext_js/@dimakorotkov/tinymce-mathjax/plugin.min.js' },
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
              ],
              toolbar: 'code | undo redo | formatselect | ' +
                'bold italic underline backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | image | media | mathjax | help',
              mathjax: {
                lib: '/ext_js/mathjax/es5/tex-mml-chtml.js', //required path to mathjax
              },
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
              selector: 'textarea#file-picker',
              file_picker_types: 'image',
              automatic_uploads: true,
              /* and here's our custom image picker*/
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                /*
                  Note: In modern browsers input[type="file"] is functional without
                  even adding it to the DOM, but that might not be the case in some older
                  or quirky browsers like IE, so you might want to add it to the DOM
                  just in case, and visually hide it. And do not forget do remove it
                  once you do not need it anymore.
                */

                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              },
            }}
          />
        </div>
      </div>
      <div className="flex justify-end">
        {isRemovable && (
          <button
            className="p-2 rounded-full bg-red-500 hover:bg-red-700 hover:shadow-lg text-white"
            onClick={() => onRemoveChoice(choice.code)}
          >
            <BiTrash className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

export default function FormDataPage({
  isShow,
  rootModel,
  setRootModel,
  selectedQuestionDetail
}) {

  const dispatch = useDispatch()
  const history = useHistory()

  const defaultChoices = [
    {
      code: new Date().setSeconds(new Date().getSeconds() + 29).toString(),
      label: 'A',
      choice: ''
    },
    {
      code: new Date().setSeconds(new Date().getSeconds() + 30).toString(),
      label: 'B',
      choice: ''
    },
    {
      code: new Date().setSeconds(new Date().getSeconds() + 31).toString(),
      label: 'C',
      choice: ''
    },
    {
      code: new Date().setSeconds(new Date().getSeconds() + 32).toString(),
      label: 'D',
      choice: ''
    },
  ]

  const [formModel, setFormModel] = useState({
    kodeSoal: '-',
    skorSoal: 0,
    initSoalText: '',
    choices: defaultChoices,
    correctAnswerLabel: null,
    questionRefEvt: null,
    choicesEditorRefsEvt: [],
    kunciJawabanLabel: null,
    mapelOpts: {
      selectedOpt: null,
      data: rootModel.mapelIdLabelList.map(mil => ({ value: mil.id, label: mil.label })),
      isLoad: false
    },
    jenjangOpts: {
      selectedOpt: null,
      data: rootModel.jenjangIdLabelList.map(jil => ({ value: jil.id, label: jil.label })),
      isLoad: false
    },
    materiOpts: {
      selectedOpt: null,
      data: rootModel.materiIdLabelList.map(mil => ({ value: mil.id, label: mil.label })),
      isLoad: false
    },
    audioFile: null,
    audioFileAsPayload: {
      audioFileName: '',
      audioFileBase64: null
    },
    initSolutionText: '',
    activeSheet: 'question', //question or solution
    solutionVideoLink: '',
  })

  const questionRef = useRef(null)
  const solutionRef = useRef(null)
  const choicesEditorRefs = useRef(Array.from({ length: 6 }, (_, i) => createRef()))
  const topBoxRef = useRef(null)
  const audioInputRef = useRef(null)
  const audioRef = useRef(null)

  const questionRefEvtHandler = (evt) => {
    setFormModel({ ...formModel, questionRefEvt: evt })
  }

  const choiceEditorRefsEvtHandler = (evt) => {
    setFormModel({ ...formModel, choicesEditorRefsEvt: [...formModel.choicesEditorRefsEvt, { l: formModel.choicesEditorRefsEvt.length + 1, e: evt }] })
  }

  const setKunciJawabanLabel = (label) => ({ ...formModel, kunciJawabanLabel: label })
  const openQuestionSheet = (m) => ({
    ...m,
    activeSheet: 'question'
  })
  const openSolutionSheet = (m) => ({
    ...m,
    activeSheet: 'solution'
  })

  useEffect(() => {
    setFormModel(latestM => ({
      ...latestM,
      mapelOpts: {
        ...latestM,
        data: rootModel.mapelIdLabelList.map(mil => ({ value: mil.id, label: mil.label }))
      }
    }))
  }, [rootModel.mapelIdLabelList])

  useEffect(() => {
    setFormModel(latestM => ({
      ...latestM,
      jenjangOpts: {
        ...latestM,
        data: rootModel.jenjangIdLabelList.map(jil => ({ value: jil.id, label: jil.label }))
      }
    }))
  }, [rootModel.jenjangIdLabelList])

  useEffect(() => {
    setFormModel(latestM => ({
      ...latestM,
      materiOpts: {
        ...latestM,
        data: rootModel.materiIdLabelList.map(mil => ({ value: mil.id, label: mil.label }))
      }
    }))
  }, [rootModel.materiIdLabelList])

  // useEffect(() => {
  //   setFormModel(latestM => ({ ...latestM, materiOpts: { ...formModel.materiOpts, selectedOpt: null } }))
  // }, [formModel.jenjangOpts.selectedOpt, formModel.mapelOpts.selectedOpt])

  useEffect(() => {
    if (!isShow) {
      resetForm()
    }
  }, [isShow])

  useEffect(() => {
    if (selectedQuestionDetail) {
      getDataDetail(selectedQuestionDetail.kodeSoal)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {

              setFormModel(latestM => ({
                ...latestM,
                kodeSoal: payload.soal.kodeSoal,
                skorSoal: payload.soal.skorSoal,
                initSoalText: payload.soal.redaksiSoal,
                skorSoal: payload.soal.skorSoal,
                choices: payload.pilihanJawaban.map(j => ({ code: j.kodeJawaban, label: j.label, choice: j.jawaban })),
                kunciJawabanLabel:
                  payload.pilihanJawaban.find(j => j.kodeJawaban === payload.soal.kunciJawaban) ?
                    payload.pilihanJawaban.find(j => j.kodeJawaban === payload.soal.kunciJawaban).label
                    :
                    null,
                mapelOpts: { ...latestM.mapelOpts, selectedOpt: { label: payload.mapel.label, value: payload.mapel.id } },
                jenjangOpts: { ...latestM.jenjangOpts, selectedOpt: { label: payload.jenjang.label, value: payload.jenjang.id } },
                materiOpts: { ...latestM.materiOpts, selectedOpt: { label: payload.materi.label, value: payload.materi.id } },
                activeSheet: 'question',
                audioFile: null,
                audioFileServer: payload.soal.audioFile,
                initSolutionText: payload.soal.solutionText,
                solutionVideoLink: payload.soal.solutionVideoLink
              }))

            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(err => { })
        .finally(() => { })

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
    else {
      setFormModel({
        ...formModel,
        kodeSoal: '-',
        initSoalText: '',
        choices: defaultChoices,
        kunciJawabanLabel: null,
        mapelOpts: { ...formModel.mapelOpts, selectedOpt: null },
        activeSheet: 'question',
        audioFile: null,
        audioFileServer: null,
        skorSoal: 0,
        initSolutionText: '',
        solutionVideoLink: '',
        jenjangOpts: { ...formModel.jenjangOpts, selectedOpt: null },
        mapelOpts: { ...formModel.mapelOpts, selectedOpt: null },
        materiOpts: { ...formModel.materiOpts, selectedOpt: null }
      })
    }

  }, [selectedQuestionDetail])

  useEffect(() => {
    if (formModel.audioFile) {
      const reader = new FileReader()

      reader.readAsDataURL(formModel.audioFile)
      reader.onload = () => {
        setFormModel({
          ...formModel,
          audioFileAsPayload: {
            audioFileName: formModel.audioFile.name,
            audioFileBase64: reader.result.split('base64,')[1]
          }
        })
      }
      reader.onerror = () => {
        alert('Error upload audio file')
      }
    }
    else {
      setFormModel({
        ...formModel,
        audioFileAsPayload: {
          audioFileName: formModel.audioFile,
          audioFileBase64: null
        }
      })
    }
  }, [formModel.audioFile])

  function resetForm() {
    setFormModel({
      ...formModel,
      kodeSoal: '-',
      initSoalText: '',
      choices: defaultChoices,
      kunciJawabanLabel: null,
      activeSheet: 'question',
      initSolutionText: '',
      audioFile: null,
      audioFileServer: null,
      skorSoal: 0,
      initSolutionText: '',
      solutionVideoLink: '',
      jenjangOpts: { ...formModel.jenjangOpts, selectedOpt: null },
      mapelOpts: { ...formModel.mapelOpts, selectedOpt: null },
      materiOpts: { ...formModel.materiOpts, selectedOpt: null }
    })
    if (questionRef.current) {
      questionRef.current.setContent('')
    }
    if (solutionRef.current) {
      solutionRef.current.setContent('')
    }
    if (choicesEditorRefs.current && choicesEditorRefs.current.reduce((acc, curr) => acc && curr.current, true)) {
      choicesEditorRefs.current.map(r => r.current.setContent(''))
    }
  }

  function addNewChoice() {
    if (formModel.choices.length >= 6) return;

    const newChoice = { code: new Date().toString(), label: '', choice: '' }

    setFormModel({ ...formModel, choices: [...formModel.choices, newChoice] })
  }

  function removeChoice(code) {
    if (formModel.choices.length < 3) return;

    setFormModel({ ...formModel, choices: formModel.choices.filter(c => c.code !== code) })
  }

  function onChoiceLabelChanged(code, text) {
    const choiceUpdate = formModel.choices.find(c => c.code === code)
    if (!choiceUpdate) return;

    const updChoices = formModel.choices.filter(c => c.code !== code)

    setFormModel({ ...formModel, choices: [...updChoices, { ...choiceUpdate, label: text }] })
  }

  function onSelectAudioFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setFormModel({ ...formModel, audioFile: null })
      return
    }

    setFormModel({
      ...formModel,
      audioFile: e.target.files[0]
    })
  }

  function onSelectJenjang() {
    setFormModel({ ...formModel, jenjangOpts: { ...formModel.jenjangOpts, isLoad: true } })

    getJenjangIdLabel()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setJenjangIdLabel(latestM, payload)
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setFormModel({ ...formModel, jenjangOpts: { ...formModel.jenjangOpts, isLoad: false } })
      })
  }

  function onSelectMapel() {
    setFormModel({ ...formModel, mapelOpts: { ...formModel.mapelOpts, isLoad: true } })

    getMapelIdLabel()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setMapelIdLabel(latestM, payload)
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setFormModel({ ...formModel, mapelOpts: { ...formModel.mapelOpts, isLoad: false } })
      })
  }

  function onSelectMateri() {
    setFormModel({ ...formModel, materiOpts: { ...formModel.materiOpts, isLoad: true } })

    getMateriIdLabel(
      formModel.jenjangOpts.selectedOpt ? formModel.jenjangOpts.selectedOpt.value : null,
      formModel.mapelOpts.selectedOpt ? formModel.mapelOpts.selectedOpt.value : null
    )
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setMateriIdLabel(latestM, payload)
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { setFormModel({ ...formModel, materiOpts: { ...formModel.materiOpts, isLoad: false } }) })
  }


  function onSave() {

    dispatch(setIsHomeProcess(true))

    const audioPayload =
      formModel.audioFileAsPayload.audioFileBase64 ?
        {
          audioFileName: formModel.audioFileAsPayload.audioFileName,
          audioBase64Content: formModel.audioFileAsPayload.audioFileBase64
        }
        :
        null

    const payload = {
      kodeSoal: selectedQuestionDetail ? formModel.kodeSoal : null,
      soal: questionRef.current.getContent(),
      skorSoal: parseInt(formModel.skorSoal),
      kunciJawaban: formModel.kunciJawabanLabel,
      kodeMateri: formModel.materiOpts.selectedOpt ? formModel.materiOpts.selectedOpt.value : null,
      kodeJenjang: formModel.jenjangOpts.selectedOpt ? formModel.jenjangOpts.selectedOpt.value : null,
      kodeMapel: formModel.mapelOpts.selectedOpt ? formModel.mapelOpts.selectedOpt.value : null,
      pilihanJawaban: formModel.choices.map((c, i) => ({ label: c.label, jawaban: choicesEditorRefs.current[i].current.getContent() })),
      audioFile: audioPayload,
      solutionVideoLink: formModel.solutionVideoLink,
      solutionText: solutionRef.current.getContent()
    }

    if (!selectedQuestionDetail) {
      setRootModel(setStartSavingData(rootModel))

      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: soalCreated => {
              setRootModel(latestM =>
                openHomePage(
                  setStartRefetchData(
                    setStartRefetchDataSummary(
                      latestM
                    )
                  )
                )
              )
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: _ => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status == 401) {
              // setInnerModel(latestM => setErrors(latestM, ['Unauthorized']))
            }
            else if (error.response.status == 400) {
              // setInnerModel(latestM => setErrors(latestM, ['Bad Request: ' + error.response.data]))
            }
            else {
              // setInnerModel(latestM => setErrors(latestM, ['Unexpected Error ']))
            }
          }
        })
        .finally(() => {
          dispatch(setIsHomeProcess(false))
          setRootModel(latestM => setFinishSavingData(latestM))
        })
    }
    else {
      setRootModel(setStartSavingData(rootModel))

      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: _ => {
              setRootModel(openHomePage(rootModel))
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: () => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status == 401) {
              // setInnerModel(latestM => setErrors(latestM, ['Unauthorized']))
            }
            else if (error.response.status == 400) {
              // setInnerModel(latestM => setErrors(latestM, ['Bad Request: ' + error.response.data]))
            }
            else {
              // setInnerModel(latestM => setErrors(latestM, ['Unexpected Error ']))
            }
          }
        })
        .finally(() => {
          dispatch(setIsHomeProcess(false))
          setRootModel(latestM => setFinishSavingData(latestM))
        })
    }
  }

  const customStyles = {
    menu: styles => ({ ...styles, zIndex: 99 })
  }

  return (
    <Transition
      show={isShow}
      enter="transform transition duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transform transition ease-out duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex space-x-2 pb-10">
        <div
          className="relative"
          style={{
            width: "300px"
          }}
        >
          <div
            className="p-2 bg-white rounded-md sticky top-16"
          >
            <div className=" flex">
              <button
                className="p-2 hover:bg-gray-200 rounded-md"
                onClick={() => setRootModel(openHomePage(rootModel))}
              >
                <RiArrowLeftLine className="h-6 w-6" />
              </button>
            </div>
            <div
              className="space-y-3 py-2"
            >
              <div
                className={"px-2"}
              >
                <div className="text-left">
                  <div>
                    Kode Soal
                </div>
                  <div>
                    <input
                      ref={topBoxRef}
                      className="w-full border-b pb-2 outline-none text-left"
                      value={formModel.kodeSoal}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div
                className={"px-2"}
              >
                <div className="text-left">
                  <div>
                    Skor soal
                </div>
                  <div>
                    <input
                      type="number"
                      className="w-full border-b pb-2 outline-none text-left"
                      value={formModel.skorSoal}
                      onChange={e => setFormModel({ ...formModel, skorSoal: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div
                className={"px-2"}
              >
                <div className="text-left">
                  <div>
                    Jenjang
                  </div>
                  <div>
                    <Select
                      value={formModel.jenjangOpts.selectedOpt}
                      options={formModel.jenjangOpts.data}
                      isLoading={formModel.jenjangOpts.isLoad}
                      onFocus={onSelectJenjang}
                      menuPlacement="auto"
                      className="w-full border border-gray-200 rounded-md text-left"
                      onChange={(value) => {
                        setFormModel({
                          ...formModel,
                          jenjangOpts: {
                            ...formModel.jenjangOpts,
                            selectedOpt: value
                          },
                          materiOpts: {
                            ...formModel.materiOpts,
                            selectedOpt: null
                          }
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={"px-2"}
              >
                <div className="text-left">
                  <div>
                    Mapel
                  </div>
                  <div>
                    <Select
                      value={formModel.mapelOpts.selectedOpt}
                      options={formModel.mapelOpts.data}
                      isLoading={formModel.mapelOpts.isLoad}
                      onFocus={onSelectMapel}
                      menuPlacement="auto"
                      className="w-full border border-gray-200 rounded-md text-left"
                      onChange={(value) => {
                        setFormModel({
                          ...formModel,
                          mapelOpts: {
                            ...formModel.mapelOpts, selectedOpt: value
                          },
                          materiOpts: {
                            ...formModel.materiOpts,
                            selectedOpt: null
                          }
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={"px-2"}
              >
                <div className="text-left">
                  <div>
                    Materi
                  </div>
                  <div>
                    <Select
                      value={formModel.materiOpts.selectedOpt}
                      options={formModel.materiOpts.data}
                      isLoading={formModel.materiOpts.isLoad}
                      onFocus={onSelectMateri}
                      menuPlacement="auto"
                      className="w-full border border-gray-200 rounded-md text-left"
                      onChange={(value) => {
                        setFormModel({ ...formModel, materiOpts: { ...formModel.materiOpts, selectedOpt: value } })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="w-full py-2 rounded-md bg-blue-500 hover:bg-blue-700 hover:shadow-lg text-white"
                  onClick={onSave}
                >
                  Simpan
                </button>
              </div>
            </div>

          </div>
        </div>
        <div
          className="flex-1 rounded-lg bg-white relative space-y-3"
        >
          <div
            className="pt-2 px-5 flex text-left font-bold bg-gray-500"
          >
            <button
              className={
                "p-2 px-5 h-full border-t-2 " + (formModel.activeSheet === 'question' ? " bg-white border-blue-700 " : " bg-gray-300 hover:bg-gray-100 border-transparent ")
              }
              onClick={() => setFormModel(openQuestionSheet(formModel))}
            >
              Pertanyaan
          </button>
            <button
              className={
                "p-2 px-5 h-full border-t-2 " + (formModel.activeSheet === 'solution' ? " bg-white border-blue-700 " : " bg-gray-300 hover:bg-gray-100 border-transparent ")
              }
              onClick={() => setFormModel(openSolutionSheet(formModel))}
            >
              Pembahasan
          </button>
          </div>
          <SolutionBox
            isShow={formModel.activeSheet === 'solution'}
            formModel={formModel}
            setFormModel={setFormModel}
            solutionRef={solutionRef}
          />
          <div
            className={formModel.activeSheet === "question" ? " px-2 " : " hidden "}
          >
            <textarea className="hidden" id="file-picker"></textarea>
            <div className="pb-3 px-2">
              <div className="py-2 flex">
                <input
                  key={formModel.audioFile ? 'e' : 'n'}
                  ref={audioInputRef}
                  type="file"
                  className="hidden"
                  onChange={(e) => { onSelectAudioFile(e) }}
                  accept="audio/*"
                />
                {formModel.audioFile || formModel.audioFileServer ?
                  (<div className="flex space-x-2">
                    <audio ref={audioRef} controls controlsList="nodownload">
                      <source src={formModel.audioFileServer ? formModel.audioFileServer : URL.createObjectURL(formModel.audioFile)} type="audio/mp3" />
                    </audio>
                    <button
                      className="rounded-full px-4 bg-gray-200"
                      onClick={() => setFormModel({ ...formModel, audioFile: null, audioFileServer: null })}
                    >
                      <RiCloseFill className="h-5 w-5" />
                    </button>
                  </div>)
                  :
                  (<button
                    className="flex items-center space-x-2 p-2 px-3 rounded-mg bg-blue-500 text-white hover:bg-blue-700 hover:shadow-lg"
                    onClick={() => audioInputRef.current.click()}
                  >
                    <AiOutlinePlus className="h-5 w-5" />
                    <label>Audio</label>
                  </button>)
                }
              </div>
              <div>
                <Editor
                  // apiKey="xh6ky1k77whs74aqd1wac0wzxmpdixv4hdoqw7bco18oo214"
                  tinymceScriptSrc="/ext_js/tinymce/tinymce.min.js"
                  onInit={(evt, editor) => { questionRef.current = editor; questionRefEvtHandler(evt) }}
                  initialValue={formModel.initSoalText}
                  init={{
                    license_key: 'gpl',
                    height: 400,
                    menubar: false,
                    external_plugins: { 'mathjax': '/ext_js/@dimakorotkov/tinymce-mathjax/plugin.min.js' },
                    plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                    ],
                    toolbar: 'code | undo redo | formatselect | ' +
                      'bold italic underline backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | image | media | mathjax | help',
                    mathjax: {
                      lib: '/ext_js/mathjax/es5/tex-mml-chtml.js', //required path to mathjax
                    },
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                    selector: 'textarea#file-picker',
                    file_picker_types: 'image',
                    automatic_uploads: true,
                    /* and here's our custom image picker*/
                    file_picker_callback: function (cb, value, meta) {
                      var input = document.createElement('input');
                      input.setAttribute('type', 'file');
                      input.setAttribute('accept', 'image/*');

                      /*
                        Note: In modern browsers input[type="file"] is functional without
                        even adding it to the DOM, but that might not be the case in some older
                        or quirky browsers like IE, so you might want to add it to the DOM
                        just in case, and visually hide it. And do not forget do remove it
                        once you do not need it anymore.
                      */

                      input.onchange = function () {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function () {
                          /*
                            Note: Now we need to register the blob in TinyMCEs image blob
                            registry. In the next release this part hopefully won't be
                            necessary, as we are looking to handle it internally.
                          */
                          var id = 'blobid' + (new Date()).getTime();
                          var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                          var base64 = reader.result.split(',')[1];
                          var blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);

                          /* call the callback and populate the Title field with the file name */
                          cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                      };

                      input.click();
                    }
                  }}
                />
              </div>
            </div>
            <div className="text-left font-bold px-2">
              Pilihan Jawaban
          </div>
            <div className="px-2">
              {choicesEditorRefs.current.length > 0 && formModel.choices.map((c, i) => (
                <QuestionChoiceBox
                  key={c.code}
                  choice={{
                    code: c.code,
                    label: c.label,
                    answer: c.choice
                  }}
                  onRemoveChoice={removeChoice}
                  onLabelChange={onChoiceLabelChanged}
                  extRef={choicesEditorRefs.current[i]}
                  onInitEditor={choiceEditorRefsEvtHandler}
                  isRemovable={formModel.choices.length > 2}
                />
              ))}
            </div>
            {formModel.choices.length < 6 && (
              <div className="flex py-2 px-2 justify-center font-bold border-t border-b border-gray-200">
                <button
                  className="py-2 px-5 bg-blue-500 hover:bg-blue-900 hover:shadow-lg  rounded-lg text-white"
                  onClick={addNewChoice}
                >
                  Tambah Pilihan
              </button>
              </div>
            )}
            <div className="text-center font-bold ">
              Kunci Jawaban
            </div>
            <div className="flex justify-center space-x-2 pb-10">
              {formModel.choices.map((c, i) => (
                <button
                  className={
                    "rounded-full p-2 px-4 border " +
                    (formModel.kunciJawabanLabel === c.label ? " border-blue-500 bg-blue-500 text-white " : " border-gray-500 hover:bg-blue-200 ")
                  }
                  onClick={() => setFormModel(setKunciJawabanLabel(c.label))}
                >
                  {c.label}
                </button>
              ))}
            </div>
          </div>

        </div>
      </div>
    </Transition>
  )
}