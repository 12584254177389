import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/jenjang/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/data-jenjang"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/jenjang"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/jenjang"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/jenjang-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeJenjang) => {
  const finalUrl =
    window._HOST + "admin/jenjang?kode-jenjang=" + kodeJenjang

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}
