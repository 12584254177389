export const initModel = {
  email: {
    email: '',
    error: ''
  },
  password: {
    password: '',
    isOpen: false,
    error: ''
  },
  activeField: 'email',
  isLoading: false,
  errors: []
}

export const getEmail = (model) => model.email.email
export const getPassword = (model) => model.password.password
export const isPasswordOpen = (model) => model.password.isOpen
export const isLoading = (model) => model.isLoading

export const togglePassword = (model) => ({ ...model, password: { ...model.password, isOpen: !model.password.isOpen } })

export const updateEmail = (model, text) => ({ ...model, email: { ...model.email, email: text } })
export const updatePassword = (model, text) => ({ ...model, password: { ...model.password, password: text } })

export const setEmailActive = (model) => ({ ...model, activeField: 'email' })
export const setPasswordActive = (model) => ({ ...model, activeField: 'password' })

export const setIsLoading = (model, isLoading) => ({ ...model, isLoading: isLoading })

export const setError = (model, errors) => ({ ...model, errors: errors })
export const emptyError = (model) => ({ ...model, errors: [] })